import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import ctxMemo from '@vl/redata/ctxMemo.macro';
import { Button } from '@uz/unitz-components-web/Button';
import { Link } from '@uz/unitz-components-web/Link';
import { ctx } from '@vl/redata';
import gstyles from '@vl/gstyles';
import { Row, Col } from 'antd';
import { renderPropsComposer } from '@vl/mod-utils/PropsComposer';
import { UnAuthRedirect } from '@uz/unitz-tool-components/AppRedirect';
import CustomTable from '@uz/unitz-components-web/CustomTable';
import FilterWithTwoOption from '@uz/unitz-tool-components/FilterWithTwoOption';
import LoadingScreen from '@uz/unitz-components-web/LoadingScreen';
import _ from 'lodash';

const Index = () => {
  // @TODO: validate missing required input
  return (
    <DIV className="pageContainer">
      <UnAuthRedirect to={ctx.apply('authModel.generateLoginRedirectUrl')}>
        <div className="p-4 h-full">
          <div className="course-list">
            {ctxMemo(
              renderPropsComposer(
                {
                  matcher: (props) => !_.get(props, 'items.length') && !_.get(props, 'isLoading') && !ctx.apply('paginationModel.hasFilterArgs'),
                  render: () => (
                    <>
                      <div className="flex items-center justify-between w-full">
                        <div className="text-main uppercase font-semibold text-base">
                          {ctx.apply('i18n.t', 'ZoomToolCourse.title')}
                        </div>
                      </div>

                      <Row justify="center" className="py-16">
                        <Col span={12}>
                          <div className="flex flex-col items-center justify-center">
                            <div className="mb-4">
                              {gstyles.icons({
                                name: 'courseEmpty',
                                size: 152,
                              })}
                            </div>
                            <div className="mb-6 text-center text-2xl font-semibold text-sub">
                              {ctx.apply('i18n.t', 'ZoomToolCourse.empty_text1')}
                            </div>
                            <div className="text-2xl text-center font-semibold text-sub">
                              {ctx.apply('i18n.t', 'ZoomToolCourse.empty_text2')}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </>
                  ),
                },
                {
                  matcher: (props) => !_.get(props, 'items.length') && !!_.get(props, 'isLoading'),
                  render: () => (
                    <div className="fixed flex justify-center items-center w-full h-full top-0 left-0 select-none bg-white200">
                      <LoadingScreen />
                    </div>
                  ),
                },
                {
                  matcher: (props) => !!_.get(props, 'items.length') || ctx.apply('paginationModel.hasFilterArgs'),
                  render: () => (
                    <>
                      <FilterWithTwoOption />
                      <div className="flex justify-between items-center my-4">
                        <div className="text-main uppercase font-semibold text-base">
                          {ctx.apply('i18n.t', 'ZoomToolCourse.title')} ({ctx.get('paginationModel.total')})
                        </div>
                      </div>

                      <div className="bg-white500 rounded-lg pb-4">
                        <CustomTable
                          columns={ctx.get('tableColumn.columns')}
                          dataSource={ctx.get('tableColumn.dataSource', [])}
                          rowKey={(rec) => _.get(rec, 'id')}
                          loading={ctx.get('paginationModel.isLoading')}
                          pagination={ctx.get('tableColumn.pagination')}
                          onChange={ctx.get('paginationModel.onChange')}
                          size="middle"
                        />
                      </div>
                    </>
                  ),
                },
                () => null
              )
            )(ctx.get('paginationModel'))}
          </div>
        </div>
      </UnAuthRedirect>
    </DIV>
  );
};

export default displayName(Index);
