import React from 'react';
import { bindings, hook } from '@vl/redata';
import useLoadingModel from '@vl/hooks/useLoadingModel';
import PaginationModel from '@uz/unitz-models/PaginationModel';
import B2BCourseModel from '@uz/unitz-models/B2BCourseModel';
import B2BMemberFormatter from '@uz/unitz-models/B2BMemberModel/formatter';
import useLocalStorage from '@vl/hooks/useLocalStorageWeb';
import useFilterForm from '@vl/hooks/useFilterForm';
import useRoute from '@vl/hooks/useGbRouteDe';
import CourseFormatter from '@uz/unitz-models/CourseModel/formatter';
import { useFormik } from 'formik';
import Link from '@uz/unitz-components-web/Link';
import ActionMenu from '@uz/unitz-components-web/ActionMenu';
import * as Yup from 'yup';
import { Avatar, Tooltip } from 'antd';
import _ from 'lodash';
import gstyles from '../../gstyles/index.web';
import { ACL } from '@vl/mod-utils/ACL';

const bindData = bindings({
  pageContainer: {
    rules: [
      [
        'data',
        {
          data: {
            loadingModel: hook(useLoadingModel(() => ({ fetchProfile: false }))),

            filterForm: hook((ctx) => {
              const route = useRoute();
              const routeParams = route.getParams();
              const search_data = _.get(routeParams, 'search', '');
              const status = _.get(routeParams, 'status', '');
              const form = useFormik({
                enableReinitialize: true,
                initialValues: {
                  status,
                  search: search_data,
                },

                onSubmit: async (values, actions) => {
                  try {
                    const memberFilterPagingModel = ctx.apply('REF.getRef', 'CoursePagingModel');
                    if (memberFilterPagingModel) {
                      await memberFilterPagingModel.onLoadPage(0);
                    }
                    const filterUrl = ctx.apply('routeStore.toUrl', 'toolAccountCourses', _.omit(form.values, ['']));
                    route.navigate(filterUrl);
                  } catch (error) {
                    actions.setStatus({
                      error,
                    });
                  }
                },

                validationSchema: Yup.object().shape({}),
              });

              _.assign(form, {
                clearFilter: () => {
                  const filterUrl = ctx.apply('routeStore.toUrl', 'toolAccountCourses');
                  route.navigateExternal(filterUrl);
                },
              });

              return form;
            }),

            getFilterArgs: hook((ctx) => {
              return () => {
                const values = ctx.get('filterForm.values', {});
                const status = _.get(values, 'status', '');
                const search = _.get(values, 'search', '');
                const args = {
                  ...(search ? { search } : {}),
                  ...(status ? { status } : {}),
                };
                if (status === 'all') _.unset(args, 'status');
                return args;
              };
            }),

            handleChangeSubmit: hook((ctx) => {
              return React.useMemo(() => _.debounce(() => ctx.apply('filterForm.handleSubmit'), 599), []);
            }),

            paginationModel: hook((ctx) => {
              const ref = React.useRef({});
              const userId = ctx.apply('authModel.getUserId');
              const account_id = ctx.apply('accountModel.getAccountId');
              _.assign(ref.current, { getFilterArgs: ctx.get('getFilterArgs') });
              const pagingModel = React.useMemo(() => {
                const dataQuery = `
                  b2b_search_course (
                    args: $args,
                    where: $course_bool_exp
                    order_by: {created_at: desc_nulls_last}
                  ){
                    id
                    name
                    photo_url
                    session_duration
                    account_id
                    materials
                    start_at
                    link_urls
                    status
                    course_teacher {
                      id
                      role
                      member {
                        member_profile {
                          first_name
                          last_name
                          email
                          avatar_url
                        }
                      }
                    }
                    course_enrolls {
                      id
                      member {
                        member_profile {
                          first_name
                          last_name
                          email
                          avatar_url
                        }
                      }
                    }
                    course_rooms {
                      id
                      name
                      photo_url
                      description
                      start_at
                      end_at
                      status
                      link_urls
                      materials
                      join_url
                    }
                    ongoing_course_room_aggregate: course_rooms_aggregate(
                      where: {status: {_in: ["ongoing", "upcoming", "draft"]}}
                    ) {
                      aggregate {
                        count
                      }
                    }
                    total_course_room_aggregate: course_rooms_aggregate {
                      aggregate {
                        count
                      }
                    }
                  }
                `;
                const pagingModel = PaginationModel.fromConfig({
                  Model: B2BCourseModel,
                  dataQuery,
                  aggQuery: `
                    b2b_search_course_aggregate(args: $args, where: $course_bool_exp) {
                      aggregate {
                        count
                      }
                    }
                  `,
                  pageSize: 10,
                  varsList: '$args: b2b_search_course_args!, $course_bool_exp: b2b_course_bool_exp',
                  getVars: async () => {
                    const isAdmin = await ACL.checkAccess('edit_account');
                    return {
                      args: ref.current.getFilterArgs(),
                      ...(isAdmin
                        ? { course_bool_exp: { account_id: { _eq: account_id } } }
                        : {
                            course_bool_exp: {
                              account_id: { _eq: account_id },
                              _or: [
                                { course_teacher: { user_id: { _eq: userId } } },
                                { course_room_attendees: { user_id: { _eq: userId } } },
                              ],
                            },
                          }),
                    };
                  },
                });
                return pagingModel;
              }, []);

              pagingModel.hookState();

              React.useEffect(() => {
                pagingModel.onLoadPageHandler(0);
              }, [pagingModel]);

              const pagination = {
                items: pagingModel.useState('items'),
                filterArgs: ref.current.getFilterArgs(),
                page: pagingModel.useState('page'),
                total: pagingModel.useState('total'),
                isLoading: pagingModel.useState('isLoading'),
                pageSize: pagingModel.useState('pageSize'),
                hasPreviousPage: pagingModel.useState('hasPreviousPage'),
                hasNextPage: pagingModel.useState('hasNextPage'),
                hasLoadMore: pagingModel.useState('hasLoadMore'),
                hasFilterArgs: () => !!_.compact(_.values(ref.current.getFilterArgs())).length,
                onRefresh: (...args) => pagingModel.onRefresh(...args),
                onPreviousPage: (...args) => pagingModel.onPreviousPageHandler(...args),
                onNextPage: (...args) => pagingModel.onNextPageHandler(...args),
                onLoadPage: (...args) => pagingModel.onLoadPageHandler(...args),
              };

              ctx.apply('REF.setRef', 'CoursePagingModel', pagination);
              return pagination;
            }),

            allColumns: hook((ctx) => {
              const columns = [
                {
                  title: (
                    <div className="text-sub font-semibold text-center text-xs">
                      {ctx.apply('i18n.t', 'ZoomToolMember.Table.number_order')}
                    </div>
                  ),
                  width: 50,
                  render: (text, record, index) => {
                    return (
                      <div className="text-xs text-center">
                        {ctx.get('paginationModel.page') * ctx.get('paginationModel.pageSize') + index + 1}
                      </div>
                    );
                  },
                },
                {
                  title: (
                    <div className="text-sub font-semibold text-xs">
                      {ctx.apply('i18n.t', 'ZoomToolCourse.Table.name')}
                    </div>
                  ),
                  // sorter: {
                  //   toQuery: (sorter) => {
                  //     return { profile: { display_name: useFilterForm.SORTER_MAPPING[sorter.order] } };
                  //   },
                  // },
                  // defaultSortOrder: useFilterForm.getSortOrder('user.profile.display_name'),
                  render: (val) => {
                    return (
                      <div className="identify-container">
                        <Link to={ctx.apply('routeStore.toUrl', 'toolAccountCourseDetail', val)}>
                          <div className="uppercase text-brandb500 underline text-xs">
                            {CourseFormatter.displayName(ctx)(val)}
                          </div>
                        </Link>
                      </div>
                    );
                  },
                  width: 150,
                  ellipsis: true,
                  require: true,
                },
                {
                  title: (
                    <div className="text-sub font-semibold text-center text-xs">
                      {ctx.apply('i18n.t', 'ZoomToolCourse.Table.startDate')}
                    </div>
                  ),
                  width: 120,
                  key: 'start_at',
                  // sorter: {
                  //   toQuery: (sorter) => {
                  //     return { profile: { phone_number: useFilterForm.SORTER_MAPPING[sorter.order] } };
                  //   },
                  // },
                  defaultSortOrder: useFilterForm.getSortOrder('member_profile.phone'),
                  render: (val) => <div className="text-xs text-center">{CourseFormatter.startAt(ctx)(val)}</div>,
                },
                {
                  title: (
                    <div className="text-sub font-semibold text-center text-xs">
                      {ctx.apply('i18n.t', 'ZoomToolCourse.Table.teacher')}
                    </div>
                  ),
                  width: 80,
                  key: 'teachers',
                  render: (val) => (
                    <div className="flex justify-center items-center">
                      <Avatar.Group
                        maxCount={2}
                        size="small"
                        maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}
                      >
                        {_.map(_.get(val, 'course_teacher'), (item, index) => {
                          const props = {};
                          _.update(props, 'src', () => {
                            const src = B2BMemberFormatter.avatarUrl(ctx)(_.get(item, 'member'));
                            return src;
                          });
                          const tooltipProps = {
                            title: _.compact([
                              _.get(item, 'member.member_profile.first_name'),
                              _.get(item, 'member.member_profile.last_name'),
                            ]).join(' '),
                            placement: 'top',
                          };
                          return (
                            <Tooltip {...tooltipProps} key={`${_.get(item, 'id')}_${index}`}>
                              <Avatar {...props} />
                            </Tooltip>
                          );
                        })}
                      </Avatar.Group>
                    </div>
                  ),
                },
                {
                  title: (
                    <div className="text-sub font-semibold text-center text-xs">
                      {ctx.apply('i18n.t', 'ZoomToolCourse.Table.student')}
                    </div>
                  ),
                  width: 80,
                  key: 'students',
                  render: (val) => (
                    <div className="flex justify-center items-center">
                      <Avatar.Group
                        maxCount={2}
                        size="small"
                        maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}
                      >
                        {_.map(_.get(val, 'course_enrolls'), (item, index) => {
                          const props = {};
                          _.update(props, 'src', () => {
                            const src = B2BMemberFormatter.avatarUrl(ctx)(_.get(item, 'member'));
                            return src;
                          });
                          const tooltipProps = {
                            title: _.compact([
                              _.get(item, 'member.member_profile.first_name'),
                              _.get(item, 'member.member_profile.last_name'),
                            ]).join(' '),
                            placement: 'top',
                          };
                          return (
                            <Tooltip {...tooltipProps} key={`${_.get(item, 'id')}_${index}`}>
                              <Avatar {...props} />
                            </Tooltip>
                          );
                        })}
                      </Avatar.Group>
                    </div>
                  ),
                },
                {
                  title: (
                    <div className="text-sub font-semibold text-center text-xs">
                      {ctx.apply('i18n.t', 'ZoomToolCourse.Table.lesson_progress')}
                    </div>
                  ),
                  width: 50,
                  key: 'lesson_progress',
                  render: (val) => (
                    <div className="text-xs text-center">
                      {[
                        _.get(val, 'ongoing_course_room_aggregate.aggregate.count', 0),
                        _.get(val, 'total_course_room_aggregate.aggregate.count', 0),
                      ].join('/')}
                    </div>
                  ),
                },
                {
                  title: (
                    <div className="text-sub text-center font-semibold text-xs">
                      {ctx.apply('i18n.t', 'ZoomToolMember.Table.status.col_name')}
                    </div>
                  ),
                  width: 100,
                  render: (val) => {
                    // const statusClass = {
                    //   draft: 'bg-pink-500',
                    //   upcoming: 'bg-pink',
                    //   ongoing: 'bg-yellow500',
                    //   finished: 'bg-brandb500',
                    //   completed: 'bg-brandb500',
                    // };

                    const statusStyle = {
                      draft: gstyles.colors.powerPeony,
                      upcoming: gstyles.colors.powerPeony,
                      ongoing: gstyles.colors.yellow500,
                      finished: gstyles.colors.brandb500,
                      completed: gstyles.colors.brandb500,
                    };

                    return (
                      <div className="flex items-center justify-center">
                        <div
                          style={{ backgroundColor: _.get(statusStyle, _.get(val, 'status', ''), '') }}
                          className={`capitalize inline-block text-xs font-semibold text-white500 rounded-lg text-center px-2 py-1`}
                        >
                          {ctx.apply('i18n.t', `ZoomToolCourse.Status.${_.get(val, 'status', '')}`)}
                        </div>
                      </div>
                    );
                  },
                },
                {
                  title: (
                    <div className="text-sub text-center font-semibold text-xs">
                      {ctx.apply('i18n.t', 'ZoomToolCourse.Table.actions')}
                    </div>
                  ),
                  width: 120,
                  render: (val) => {
                    const courseRoom = _.first(_.get(val, 'course_rooms', []));
                    return (
                      <ActionMenu className="flex justify-center items-center">
                        {/* <ActionMenu.Item>
                          <Link to={courseRoom && ctx.apply('routeStore.toUrl', 'toolAccountRoomDetail', courseRoom)}>
                            <div className="text-yellow500 hover:text-yellow500">
                              {ctx.apply('i18n.t', 'ZoomToolCourse.Action.chat_room')}
                            </div>
                          </Link>
                        </ActionMenu.Item> */}
                        <ActionMenu.Item>
                          <Link
                            className="text-blue500 hover:text-blue500"
                            to={ctx.apply('routeStore.toUrl', 'toolAccountCourseDetail', val)}
                          >
                            {ctx.apply('i18n.t', 'ZoomToolCourse.Action.view_course')}
                          </Link>
                        </ActionMenu.Item>
                        <ActionMenu.Item>
                          <Link
                            className="text-blue500 hover:text-blue500"
                            to={ctx.apply('routeStore.toUrl', 'toolAccountCourseEdit', val)}
                          >
                            {ctx.apply('i18n.t', 'ZoomToolCourse.Action.edit_course')}
                          </Link>
                        </ActionMenu.Item>
                        <ActionMenu.Item>
                          <Link to={ctx.apply('routeStore.toUrl', 'toolAccountCourseTeachers', val)}>
                            <div className="text-blue500 hover:text-blue500">
                              {ctx.apply('i18n.t', 'ZoomToolCourse.Action.edit_teachers')}
                            </div>
                          </Link>
                        </ActionMenu.Item>
                        <ActionMenu.Item>
                          <Link to={ctx.apply('routeStore.toUrl', 'toolAccountCourseReports', val)}>
                            <div className="text-sub hover:text-sub">
                              {ctx.apply('i18n.t', 'ZoomToolCourse.Action.view_report')}
                            </div>
                          </Link>
                        </ActionMenu.Item>
                        <ActionMenu.Item>
                          <Link to={ctx.apply('routeStore.toUrl', 'toolAccountCourseStudents', val)}>
                            <div className="text-blue500 hover:text-blue500">
                              {ctx.apply('i18n.t', 'ZoomToolCourse.Action.edit_student')}
                            </div>
                          </Link>
                        </ActionMenu.Item>
                        <ActionMenu.Item>
                          <Link to={ctx.apply('routeStore.toUrl', 'toolAccountCourseStudents', val)}>
                            <div className="text-blue500 hover:text-blue500">
                              {ctx.apply('i18n.t', 'ZoomToolCourse.Action.edit_attendance')}
                            </div>
                          </Link>
                        </ActionMenu.Item>
                      </ActionMenu>
                    );
                  },
                },
              ];
              return columns;
            }),

            tableName: hook(() => 'tblCourses'),

            tableColumn: hook((ctx) => {
              const data = ctx.get('paginationModel');
              const tableName = ctx.get('tableName', 'tblCourses');
              const [tableSettings, $tableSettings] = useLocalStorage(`@UZ::TableSettings::${tableName}`, {
                columns: [],
              });
              const ref = React.useRef({});

              const allColumns = ctx.get('allColumns');

              const columns = _.filter(ctx.get('allColumns'), (col) => {
                const key = _.get(col, 'key');
                if (_.get(tableSettings, 'columns.length')) {
                  return _.get(col, 'required') || _.includes(tableSettings.columns, key);
                }
                return _.get(col, 'defaultSelected', true);
              });
              const selectedColumns = _.map(columns, 'key');
              _.assign(ref.current, { tableSettings, $tableSettings });

              return {
                pagination: {
                  pageSize: data.pageSize,
                  total: data.total,
                  showLessItems: true,
                  onChange: (page) => {
                    data.onLoadPage(page - 1);
                  },
                },
                dataSource: data.items,
                columns,
                allColumns,
                selectedColumns,
                selectColumns: (values) => {
                  ref.current.$tableSettings({ ...ref.current.tableSettings, columns: values });
                },
              };
            }),
            statusOptions: hook((ctx) => {
              const status = [
                {
                  value: 'all',
                  label: ctx.apply('i18n.t', 'ZoomToolCourse.Status.all'),
                },
                {
                  value: 'draft',
                  label: ctx.apply('i18n.t', 'ZoomToolCourse.Status.draft'),
                },
                {
                  value: 'ongoing',
                  label: ctx.apply('i18n.t', 'ZoomToolCourse.Status.ongoing'),
                },
                {
                  value: 'completed',
                  label: ctx.apply('i18n.t', 'ZoomToolCourse.Status.completed'),
                },
              ];
              return status;
            }),
          },
        },
      ],
    ],
  },
  isLoading: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => {
            return ctx.get('authModel.isLoadingRedirectResult');
          }),
        },
      ],
    ],
  },
  // notLoading: {
  //   rules: [
  //     [
  //       'display',
  //       {
  //         display: hook((ctx) => !ctx.apply('loadingModel.isLoading')),
  //       },
  //     ],
  //   ],
  // },
});

export default bindData;
